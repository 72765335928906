<template>
  <v-card :class="darkmode || 'grey lighten-5'" class="pa-4" flat>
    <Header
      :darkmode="darkmode"
      :subjects="dataSubjects"
      :dataTable="dataTable.data"
      @dialogAdd="toggleDialogQuestion"
      @changeSubjects="changeSubjects"
      @changeGrade="listEtest"
    />
    <Table
      :table="table"
      :dataTable="dataTable"
      :filter="filter"
      @setMyQuestion="setMyQuestion"
      @changeLimit="getLimit"
      @changePage="changePaginate"
      @searching="getSearch"
      @dialogEdit="toggleDialog"
      @dialogDelete="toggleDelete"
      @point="savePoint"
    />
    <AddManual
      :open="dialog"
      :subjects="dataSubjects"
      :isEdit="isEdit"
      :dataEdit="dataEdit"
      isGlobal
      @toggleDialog="toggleDialog"
    />
    <ModalConfirm
      :close="() => (this.confirmModal = false)"
      :loading="confirmLoading"
      :isOpen="confirmModal"
      :save="deleteData"
    />
    <DialogQuestion
      :open="dialogQuestion"
      :subjects="dataSubjects"
      @toggleDialog="toggleDialogQuestion"
    />
  </v-card>
</template>

<script>
import store from "@/store";
import {
  getQuestionBankGlobal,
  getListEtest,
  updateWeightGlobal,
  deleteQuestionBankGlobal
} from "@/api/admin/academic/eTest";

export default {
  components: {
    Header: () => import("./Header"),
    Table: () => import("./Table"),
    AddManual: () => import("../components/AddManual"),
    ModalConfirm: () => import("@/components/ModalConfirm"),
    DialogQuestion: () => import("./DialogQuestion")
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  created() {
    this.getQuestionBank();
  },
  data() {
    return {
      confirmModal: false,
      dialogQuestion: false,
      confirmLoading: false,
      dialog: false,
      isEdit: false,
      idDelete: 0,
      dataEdit: {},
      filter: {
        lesson: []
      },
      table: {
        limit: 10,
        page: 1,
        search: "",
        loading: false,
        my_question: this.$store.getters.g_role_type === "ADMIN" ? false : true
      },
      dataTable: { data: [] },
      dataSubjects: { loading: false, data: [] }
    };
  },
  methods: {
    setMyQuestion(param) {
      this.table.my_question = param;
      this.getQuestionBank();
    },
    toggleDialogQuestion() {
      if (this.dialogQuestion) this.getQuestionBank();
      this.dialogQuestion = !this.dialogQuestion;
    },
    async savePoint(id, point, isValidate) {
      if (isValidate) {
        this.table.loading = true;
        const body = { id: id, point: point };
        try {
          const response = await updateWeightGlobal(body);
          if (response.data.code) {
            this.snackBar(
              true,
              this.$i18n.t("e_test.msg.success_update_weight")
            );
            this.getQuestionBank();
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          this.snackBar(false, error);
        }
        this.table.loading = false;
      } else this.getQuestionBank();
    },
    async deleteData() {
      this.confirmLoading = true;
      try {
        const response = await deleteQuestionBankGlobal({ id: this.idDelete });
        if (response.data.code) {
          this.snackBar(true, this.$i18n.t("e_test.msg.question_bank_delete"));
          this.getQuestionBank();
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.confirmLoading = false;
      this.toggleDelete();
    },
    toggleDelete(id) {
      this.idDelete = id || 0;
      this.confirmModal = !this.confirmModal;
    },
    getLimit(page, limit) {
      this.table.page = page;
      this.table.limit = limit;
      this.getQuestionBank();
    },
    getSearch(value) {
      this.table.search = value;
      this.getQuestionBank();
    },
    changePaginate(page) {
      this.table.page = page;
      this.getQuestionBank();
    },
    changeSubjects(idSubjects) {
      this.filter.lesson = [idSubjects];
      this.getQuestionBank();
    },
    toggleDialog(isEdit, dataEdit) {
      this.isEdit = false;
      if (isEdit) {
        this.isEdit = isEdit;
        this.dataEdit = JSON.parse(JSON.stringify(dataEdit));
      }
      if (this.dialog) this.getQuestionBank();
      this.dialog = !this.dialog;
    },
    async listEtest(params) {
      if (params.grade_id.length > 0) {
        this.dataSubjects.loading = true;
        try {
          const response = await getListEtest(params);
          if (response.data.code === 1) {
            this.dataSubjects.data = response.data.data;
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          this.snackBar(false, error);
        }
        this.dataSubjects.loading = false;
      } else this.dataSubjects.data = [];
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async getQuestionBank() {
      this.table.loading = true;
      const body = {
        filter: this.filter,
        ...this.table
      };
      try {
        const response = await getQuestionBankGlobal(body);
        if (response.data.code) {
          this.dataTable = response.data.data.body;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.table.loading = false;
    }
  }
};
</script>
